import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTable, Column } from 'react-table';
import LottieAnimation from './LottieAnimation';
import animationData from '../animations/loading.json';

interface TableComponentProps {
    data: any[];
    columns: Column<any>[];
    handleEdit: (id: string) => void;
    handleDelete: (id: string) => void;
}

const TableComponent: React.FC<TableComponentProps> = ({ data, columns, handleEdit, handleDelete }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    const isDataEmpty = data.length === 0;

    return (

        <div className={`flex flex-col overflow-auto py-4 sm:py-0 border-gray-200 rounded-md  items-center` }>
            {isDataEmpty ? (
                <div className=''><LottieAnimation animationData={animationData} /></div>
               
            ) : (
                <table {...getTableProps()} className="table-auto w-full  ">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200">
                                <th className="px-4 py-4 text-left">#</th> {/* Autogenerated "#" column */}
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="px-4 py-2 text-left">
                                        {column.render('Header')}
                                    </th>
                                ))}

                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr
                                    className={``}
                                    {...row.getRowProps()}
                                >
                                    <td className="px-4 py-4 border-b border-gray-200">{index + 1}</td> {/* Index-based autogenerated "#" */}
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()} className="px-4 py-2 border-b border-gray-200">
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                    <td className="px-4 py-4 border-b border-gray-200">
                                        <button
                                            onClick={() => handleEdit(row.original.id)}
                                            className="text-blue-600 hover:text-blue-800 pr-4"
                                        >
                                            <PencilIcon className="h-6 w-6" />
                                        </button>
                                        <button onClick={() => handleDelete(row.original.id)} className="text-red-600 hover:text-red-800">
                                            <TrashIcon className="h-6 w-6" />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>)}
        </div>
    );
};

export default TableComponent;
