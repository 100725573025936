

import { useEffect, useState } from 'react'
import SubmitButton from '../Components/SubmitButton'
// import { CardComponent } from '../Components/CardComponent';
import { Tooltip } from 'react-tooltip';
import { ArrowPathIcon, DocumentDuplicateIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { Client } from '../models/client';
import { getAntecedents, getClients, getParticipation, getPlaces, getPresents, getReinforcers, getSettings } from '../firebase/firestoreAPI';
import { SelectCompontent } from '../Components/SelectCompontent';
import { CardComponent } from '../Components/CardComponent';
import openaiAPI from '../openai/openaiAPI';
import { OptionType } from '../models/optionType';
import { toast } from 'react-toastify';

function AddSummaryPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [client, setClient] = useState<OptionType>();
    const [placeOfService, setPlaceOfService] = useState<OptionType>();
    const [whoWasPresent, setWhoWasPresent] = useState<OptionType[]>([]);
    const [settings, setSettings] = useState<OptionType[]>([]);
    const [participation, setParticipation] = useState<OptionType>();
    const [behaviors, setBehaviors] = useState<OptionType[]>([]);
    const [interventions, setInterventions] = useState<OptionType[]>([]);
    const [replacements, setReplacements] = useState<OptionType[]>([]);

    const [clientsOptions, setClientsOptions] = useState<OptionType[]>([]);
    const [placeOfServiceOptions, setPlaceOfServiceOptions] = useState<OptionType[]>([]);
    const [whoWasPresentOptions, setWhoWasPresentOptions] = useState<OptionType[]>([]);
    const [settingsOptions, setSettingsOptions] = useState<OptionType[]>([]);
    const [participationOptions, setParticipationOptions] = useState<OptionType[]>([]);
    const [behaviorsOptions, setBehaviorsOptions] = useState<OptionType[]>([]);
    const [interventionsOptions, setInterventionsOptions] = useState<OptionType[]>([]);
    const [antecedentsOptions, setAntecedentsOptions] = useState<OptionType[]>([]);
    const [reinforcersOptions, setReinforcersOptions] = useState<OptionType[]>([]);
    const [reinforcers, setReinforcers] = useState<OptionType[]>([]);
    const [replacementsOptions, setReplacementsOptions] = useState([]);
    const [Abc, setAbc] = useState([{ label: '', value: '' }, { label: '', value: '' }, { label: '', value: '' }]);
    const [Abc2, setAbc2] = useState([{ label: '', value: '' }, { label: '', value: '' }, { label: '', value: '' }]);

    // const [medicalIssues, setmedicalIssues] = useState(false);
    //  const [Abc, setAbc] = useState({ antecedent: '', behavior: '', consequence: '' });
   // const [medicalIssues, setmedicalIssues] = useState(false);
    const [generatedSummary, setGeneratedSummary] = useState('')


    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();

        const data = {
            'Client': client?.label || '',
            'Place of service': placeOfService?.value || '',
            'Who was present: ': whoWasPresent.map(option => { return option.value }) || '',
            'Enviromental changes: ': settings.map(option => { return option.value }) || '',
            'Behaviors presented: ': behaviors.map(option => { return option.value }) || '',
            'Interventions: ': interventions.map(option => { return option.value }) || '',
            'Replacements: ': replacements.map(option => { return option.value }) || '',
            'Client participation: ': participation?.value || '',
            'Describe Antecedent-Behavior-Consequence use logic senteces : ': Abc.map(option => { return option.value }) || '',
            'Describe Antecedent-Behavior-Consequence use logic sentences: ': Abc2.map(option => { return option.value }) || '',
            'Reinforcers used: ': reinforcers.map(option => { return option.value }) || '',
        };

        const dataString = JSON.stringify(data, null, 2);
        console.log(dataString)

        try {
            setIsLoading(true);
            const summary = await openaiAPI.generateSummary(dataString);
            setGeneratedSummary(summary);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }

    }

    const fetchData = async () => {
        try {
            const fetchedClients = await getClients();
            const fetchedPlaces = await getPlaces();
            const fetchedPresents = await getPresents();
            const fetchedSettings = await getSettings();
            const fetchedParticipation = await getParticipation();
            const fetchedAntecedents = await getAntecedents();
            const fetchedReinforcers = await getReinforcers();

            setClientsOptions(fetchedClients.map((client: Client) => ({ label: client.name, value: client })));
            setPlaceOfServiceOptions(fetchedPlaces.map((place: any) => ({ label: place.name, value: place.name })));
            setWhoWasPresentOptions(fetchedPresents.map((present: any) => ({ label: present.name, value: present.name })));
            setSettingsOptions(fetchedSettings.map((setting: any) => ({ label: setting.name, value: setting.name })));
            setParticipationOptions(fetchedParticipation.map((participation: any) => ({ label: participation.name, value: participation.name })));
            setAntecedentsOptions(fetchedAntecedents.map((antecedent: any) => ({ label: antecedent.name, value: antecedent.name })));
            setBehaviorsOptions(client?.value.behaviors.map((behavior: any) => ({ label: behavior.label, value: behavior.value })));
            setInterventionsOptions(client?.value.interventions.map((intervention: any) => ({ label: intervention.label, value: intervention.value })));
            setReplacementsOptions(client?.value.replacements.map((replacement: any) => ({ label: replacement.label, value: replacement.value })));
            setReinforcersOptions(fetchedReinforcers.map((reinforcer: any) => ({ label: reinforcer.name, value: reinforcer.name })));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    //EXTRA FUNCTIONS
    const copyToClipboard = () => {
        navigator.clipboard.writeText(generatedSummary)
        toast.success('Summary copied to clipboard!')
    }
    const sendByEmail = () => {
        const email = 'recipient@example.com';
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(`${client?.label} Summary`)}&body=${encodeURIComponent(generatedSummary)}`;
        window.open(mailtoLink, '_blank');

    };

    useEffect(() => {
        fetchData();
    },);

    return (
        <div className='grid grids-cols-1 sm:grid-cols-2 p-4 gap-4 h-full bg relative'>
            <div className='overflow-y-hidden-full'>
                <CardComponent>
                    <div className='flex flex-col '>
                        <form className='w-full  '>
                            <div className='mb-4'>
                                <label htmlFor="client" className='block mb-2 text-sm font-bold'>Client:</label>
                                <SelectCompontent
                                    values={client}
                                    options={clientsOptions}
                                    onChange={(selectedClient: OptionType[]) => setClient(selectedClient[0])}
                                />

                            </div>

                            <div className='mb-4'>
                                <label htmlFor="place_of_service" className='block mb-2 text-sm font-bold'>Place of service:</label>
                                <SelectCompontent create values={placeOfService} options={placeOfServiceOptions} onChange={(selectedPlaceOfService: OptionType[]) => setPlaceOfService(selectedPlaceOfService[0])} />
                            </div>
                            <div className='mb-4'>
                                <label htmlFor="who_was_present" className='block mb-2 text-sm font-bold'>Who was present:</label>
                                <SelectCompontent isMulti create values={whoWasPresent} options={whoWasPresentOptions} onChange={(selectedPresents: OptionType[]) => setWhoWasPresent(selectedPresents)} />
                            </div>
                            <div className='mb-4'>
                                <label htmlFor="settings" className='block mb-2 text-sm font-bold'>Enviromental changes:</label>
                                <SelectCompontent create isMulti values={settings} options={settingsOptions} onChange={(selectedSettings: OptionType[]) => setSettings(selectedSettings)} />
                            </div>
                            <div className='mb-4'>
                                <label htmlFor="participation" className='block mb-2 text-sm font-bold'>Participation:</label>
                                <SelectCompontent values={participation} options={participationOptions} onChange={(selectedParticipation: OptionType[]) => setParticipation(selectedParticipation[0])} />
                            </div>

                            <div className='mb-4'>
                                <label htmlFor="behaviors" className='block mb-2 text-sm font-bold'>Behaviors presented:</label>
                                <SelectCompontent isMulti create values={behaviors} options={behaviorsOptions} onChange={(selectedBehaviors: OptionType[]) => setBehaviors(selectedBehaviors)} />
                            </div>
                            <div className='mb-4'>
                                <label htmlFor="interventions" className='block mb-2 text-sm font-bold'>Interventions:</label>
                                <SelectCompontent isMulti create values={interventions} options={interventionsOptions} onChange={(selectedInteventions: OptionType[]) => setInterventions(selectedInteventions)} />
                            </div>
                            <div className='grid grid-flow-col gap-4 mb-4'>
                                <CardComponent >
                                    <div>
                                        <div className='mb-4'>
                                            <label htmlFor="antecedent" className='block mb-2 text-sm font-bold'>Antecedent:</label>
                                            <SelectCompontent create values={Abc[0]} options={antecedentsOptions} onChange={(selectedAntecedent: OptionType[]) => {
                                                const updatedAbc = [...Abc];
                                                updatedAbc[0] = selectedAntecedent[0];
                                                setAbc(updatedAbc);
                                            }} />
                                        </div>
                                        <div className='mb-4'>
                                            <label htmlFor="behavior" className='block mb-2 text-sm font-bold'>Behavior:</label>
                                            <SelectCompontent create values={Abc[1]} options={behaviorsOptions} onChange={(selectedBehavior: OptionType[]) => {
                                                const updatedAbc = [...Abc];
                                                updatedAbc[1] = selectedBehavior[0];
                                                setAbc(updatedAbc);
                                            }} />
                                        </div>
                                        <div className='mb-4'>
                                            <label htmlFor="consequence" className='block mb-2 text-sm font-bold'>Consequence:</label>
                                            <SelectCompontent create values={Abc[2]} options={interventionsOptions} onChange={(selectedIntervention: OptionType[]) => {
                                                const updatedAbc = [...Abc];
                                                updatedAbc[2] = selectedIntervention[0]
                                                setAbc(updatedAbc);
                                            }} />
                                        </div>
                                    </div>

                                </CardComponent>
                                <CardComponent >
                                    <div>
                                        <div className='mb-4'>
                                            <label htmlFor="antecedent" className='block mb-2 text-sm font-bold'>Antecedent:</label>
                                            <SelectCompontent create values={Abc2[0]} options={antecedentsOptions} onChange={(selectedAntecedent: OptionType[]) => {
                                                const updatedAbc2 = [...Abc2];
                                                updatedAbc2[0] = selectedAntecedent[0];
                                                setAbc2(updatedAbc2);
                                            }} />
                                        </div>
                                        <div className='mb-4'>
                                            <label htmlFor="behavior" className='block mb-2 text-sm font-bold'>Behavior:</label>
                                            <SelectCompontent create values={Abc2[1]} options={behaviorsOptions} onChange={(selectedBehavior: OptionType[]) => {
                                                const updatedAbc2 = [...Abc2];
                                                updatedAbc2[1] = selectedBehavior[0];
                                                setAbc2(updatedAbc2);
                                            }} />
                                        </div>
                                        <div className='mb-4'>
                                            <label htmlFor="consequence" className='block mb-2 text-sm font-bold'>Consequence:</label>
                                            <SelectCompontent create values={Abc[2]} options={interventionsOptions} onChange={(selectedIntervention: OptionType[]) => {
                                                const updatedAbc2 = [...Abc2];
                                                updatedAbc2[2] = selectedIntervention[0]
                                                setAbc2(updatedAbc2);
                                            }} />
                                        </div>
                                    </div>
                                </CardComponent>

                            </div>
                            <div className='mb-4'>
                                <label htmlFor="replacements" className='block mb-2 text-sm font-bold'>Replacements:</label>
                                <SelectCompontent isMulti create values={replacements} options={replacementsOptions} onChange={(selectedReplacements: OptionType[]) => setReplacements(selectedReplacements)} />
                            </div>

                            <div className='mb-4'>
                                <label htmlFor="reinforcers" className='block mb-2 text-sm font-bold'>Reinforcers:</label>
                                <SelectCompontent isMulti create values={reinforcers} options={reinforcersOptions} onChange={(selectedReinforcers: OptionType[]) => setReinforcers(selectedReinforcers)} />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block mb-2 text-sm font-bold">
                                    Medical issues:
                                </label>
                                <input
                                    type="text"
                                    id="medical_issues"
                                    className="w-full border-gray-300 border rounded p-2"
                                />
                            </div>
                            <SubmitButton text='Generate' isLoading={isLoading} handleClick={handleSubmit} />
                        </form >
                    </div >
                </CardComponent>
            </div>
            <div className='divide-y-2 px-2'>
                <div className=' flex items-center justify-between py-4'>
                    <div className=' flex justify-center items-center'>
                        <button className='text-gray-500 hover:text-green-500 transition-colors duration-500 '>Save</button>
                    </div>
                    <div className='flex justify-center gap-2'>
                        <ArrowPathIcon data-tooltip-id="reload-tooltip" data-tooltip-content="Try again" className='h-6 w-6 hover:text-green-500 transition-colors duration-500 cursor-pointer' />
                        <Tooltip id="reload-tooltip" />
                        <EnvelopeIcon onClick={sendByEmail} data-tooltip-id="email-tooltip" data-tooltip-content="Send by email" className='w-6 h-6 hover:text-green-500 transition-colors duration-500 cursor-pointer' />
                        <Tooltip id="email-tooltip" />
                        <DocumentDuplicateIcon onClick={copyToClipboard} data-tooltip-id="copy-tooltip" data-tooltip-content="Copy" className='w-6 h-6 hover:text-green-500 transition-colors duration-500 cursor-pointer' />
                        <Tooltip id="copy-tooltip" />
                    </div>

                </div>
                <textarea
            
                    id='generated_summary'
                    value={generatedSummary}
                    onChange={(event) => setGeneratedSummary(event.target.value)}
                    className="h-full w-full focus:outline-none py-4 resize-none bg-gray-50"
                ></textarea>

            </div>
        </div >
    )
}

export default AddSummaryPage;