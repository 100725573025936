import React from 'react'

function NotFoundPage() {
  return (
    <div>
    <h1>404 Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
  )
}

export default NotFoundPage