import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import { useNavigate, Link } from 'react-router-dom';
import SubmitButton from './SubmitButton';
import { toast } from 'react-toastify';
import {Helmet} from 'react-helmet';

const LoginComponent = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setPassword(event.target.value);
    };

    const handleLogin = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/home');
        } catch (error) {
            console.log(error);
            toast.error('Invalid email or password, try again!')

        }
        setIsLoading(false);
    };

    return (

        <div className="w-full flex flex-col items-center justify-center  max-w-xs px-4 ">
            <Helmet>
                <title>Login | ABA Summary</title>
                <meta name="description" content="Login to ABA Summary to access your account and manage ABA notes efficiently." />

            </Helmet>

            <div className='mb-4 text-center'>
                <h2 className="text-3xl font-bold pb-4">Welcome back!</h2>
                <p className='text-sm text-gray-500'>
                    Simplify your ABA notes with AI-powered auto-completion
                </p>
            </div>
            <form className="w-full flex flex-col mt-4 ">
                <div className="mb-4">
                    <label htmlFor="email" className="block mb-2 text-sm font-bold">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        className="w-full border-gray-300 border rounded p-2 bg-white"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block mb-2 text-sm font-bold">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="w-full border-gray-300 border rounded p-2 bg-white"
                    />
                </div>
                <span className=" text-sm flex justify-end">
                    <Link to='/resetpassword' className='text-green-500 text-opacity-60 hover:text-opacity-100'>
                        Forgot password?
                    </Link>
                </span>
                <SubmitButton text='Log in' handleClick={handleLogin} isLoading={isLoading} disabled={!email || !password} />
            </form>
            <p className="pt-4 text-sm">
                Don't have an account? <Link to="/signup" className="text-green-500">Sign Up</Link>
            </p>

        </div>


    );
};

export default LoginComponent;