import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SubmitButton from './SubmitButton';
import { toast } from 'react-toastify';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';


const ChangePassword = () => {

    const [newPassword, setNewPassword] = useState('');
    const [newRePassword, setNewRePassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChangePassword = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setNewPassword(event.target.value);
    };
    const handleChangeRePassword = (event: { target: { value: React.SetStateAction<string>; }; }) => {

        setNewRePassword(event.target.value);
    };


    const handleReset = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if(newPassword===newRePassword){
            setIsLoading(false);
            toast.success('Coming soon!')

        }
        else{
            toast.error(`Password don't match. Try again.`)
            setNewPassword('');
            setNewRePassword('');
        }

    };

    return (
        <div className="w-full flex flex-col items-center gap-4">
            <div className='pb-4'>
                <h2 className="text-2xl font-bold text-center ">Create a new password</h2>
                <p className="pt-4 text-sm">Create a new password for your account.</p>
            </div>

            <form className="w-full max-w-xs flex flex-col ">
                <div className="mb-4">
                    <label htmlFor="text" className="block mb-2 text-sm font-bold">
                        New Password
                    </label>
                    <input
                        type="text"
                        value={newPassword}
                        onChange={handleChangePassword}
                        className="w-full border-gray-300 border rounded p-2 bg-white"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="text" className="block mb-2 text-sm font-bold">
                        Confirm password
                    </label>
                    <input
                        type="text"
                        value={newRePassword}
                        onChange={handleChangeRePassword}
                        className="w-full border-gray-300 border rounded p-2 bg-white"
                    />
                </div>

                <SubmitButton text='Reset password' handleClick={handleReset} isLoading={isLoading} disabled={!newRePassword} />
            </form>
            <div className="pt-6 text-sm flex justify-center items-center gap-4 text-gray-400 hover:text-green-500 transition-colors duration-500">
                <ArrowLeftIcon className='h-6 w-6' />
                <Link to="/login" className="">Back to Log in </Link>
            </div >
        </div>
    );
};

export default ChangePassword;