import { Helmet } from "react-helmet";

import ButtonComponent from "../Components/ButtonComponent";
import { useNavigate } from "react-router-dom";


export default function SummaryPage() {
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate(`/summaries/add`);
  }
  return (
    <div className="flex flex-col justify-center p-4">
      <Helmet>
        <title>Summaries | ABA Summary</title>
        <meta name="description" content="Manage all your clients" />
      </Helmet>
      <div className="relative">
        <div className="pt-32"> {/* Add padding-bottom to create space for the button */}
          {/* <TableComponent data={clients} columns={columns} handleDelete={handleDelete} handleEdit={handleEdit} /> */}
        </div>
        <div className="absolute top-0 right-0 ">
          <ButtonComponent text="Add summary" handleClick={handleCreate} />
        </div>
      </div>
    </div>
  )
}
