import React from 'react'
import Select from 'react-dropdown-select'
import { OptionType } from '../models/optionType'

import { XMarkIcon } from '@heroicons/react/24/outline';

interface SelectComponentProps {
    values: any,
    options: OptionType[],
    itemsColor?: string,
    isMulti?: boolean,
    create?: boolean,
    onChange: (selected: any) => void
}
const optionRender = ({ item, methods, itemsColor }: { item: any, methods: any, itemsColor: string }) => {

    const label = item?.label || '';
    return (
        <div className={`bg-${itemsColor} text-${itemsColor} flex gap-2  justify-center items-center  bg-opacity-30 py-2 px-3 rounded text-sm mr-2`}>
            <div className='text-xs'>
                {label}
            </div>
            <XMarkIcon onClick={() => methods.removeItem(() => { }, item)} className=' h-4 w-4 font-semibold  ' />
        </div>
    )
};

const itemRenderer = ({ item, methods, itemsColor }: { item: any, methods: any, itemsColor: string }) => {
    const label = item?.label || '';
    return (
        <div onClick={() => methods.addItem(item)} className={`p-4 hover:bg-${itemsColor} hover:text-${itemsColor} hover:bg-opacity-30`}>
            {label}
        </div>
    )
};
const styles = {
    height: '45px',
    width: '100%',
    border: '1px solid #E2E8F0',
    borderRadius: '0.25rem',
    padding: '0.5rem'
};

export const SelectCompontent: React.FC<SelectComponentProps> = ({ values, options, isMulti, create, itemsColor = 'green-500', onChange }) => {
    return (
        <Select values={values} options={options} onChange={onChange} searchable multi={isMulti}
            create={create} sortBy='label' optionRenderer={({ item, methods }) => optionRender({ item, methods, itemsColor })}
            itemRenderer={({ item, methods }) => itemRenderer({ item, methods, itemsColor })}
            style={styles} />
    )
}
