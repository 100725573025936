
import { initializeApp } from 'firebase/app';
import {  getAuth } from 'firebase/auth';
import { getFirestore} from 'firebase/firestore'

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDoOMuuIcVOl_WITSjfXJdx52Sg9MP2H24",
  authDomain: "aba-summary.firebaseapp.com",
  projectId: "aba-summary",
  storageBucket: "aba-summary.appspot.com",
  messagingSenderId: "957861435209",
  appId: "1:957861435209:web:e2fc56f1e270b546ceec98",
  measurementId: "G-JENYBRW9WT"
});

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);

// const emulatorHost = 'localhost';

// connectFirestoreEmulator(db, emulatorHost, 8080);
// connectAuthEmulator(auth,'http://127.0.0.1:9099')



