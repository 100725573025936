import React from 'react';

import { PlanComponent } from '../Components/PlanComponent';

export default function PlansPage() {


  const handleBasic = () => {
    window.location.href = 'https://buy.stripe.com/test_4gwaH0asi9LX1skcMM';
  };
  // const handlePro = () => {
  //   window.location.href = 'https://buy.stripe.com/test_4gwaH0asi9LX1skcMM';
  // };

  const basicBenefits = ['Access to ABA therapy session note generation tools.', 'Limited to 30 generated summaries per month.', 'Email support included.', ];
  const advancedBenefits = ['Access to ABA therapy session note generation tools.', 'Limited to 60 generated summaries per month.', 'Priority email support.','Customizable templates for session notes.'];
  const proBenefits = ['Access to ABA therapy session note generation tools.', 'Limited to 60 generated summaries per month.', 'Priority email support.','Customizable templates for session notes.','Chrome extension access for automated field population.'];
  const plans = [
    {
      type: 'Basic',
      bestFor:'Optimal for therapists with a single client.',
      price: '19.99',
      benefits: basicBenefits
    },
    {
      type: 'Advanced',
      bestFor:'Optimal for therapists with two client.',
      price: '29.99',
      benefits: advancedBenefits
    },
    {
      type: 'Pro',
      bestFor:'Optimal for therapists with three client.',
      price: '39.99',
      benefits: proBenefits
    },

  ]
  return (
    <div className='flex justify-center flex-col text-center'>
      <div >
        <h1 className='font-bold text-center text-4xl'>Pricing plans</h1>
        <p className='my-4 text-md'>Our pricing plans are designed to be affordable, flexible, and tailored to your unique needs.</p>
      </div>
      <div>

      </div>
      <div className=" grid grid-cols-1 md:grid-cols-3 p-4 gap-4 mt-4">
        {
          plans.map((plan) => (
            <PlanComponent type={plan.type} bestFor={plan.bestFor} price={plan.price} benefits={plan.benefits} handleClick={handleBasic} /> 
          ))
        }
      </div>
    </div>

  );
}
