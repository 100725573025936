import React from 'react';
import { CardComponent } from './CardComponent';
import ButtonComponent from './ButtonComponent';

interface PlanComponentProps {
    type: string;
    price: string;
    bestFor: string;
    benefits: string[];
    handleClick: () => void;
}

export function PlanComponent({ type, bestFor, price, benefits, handleClick }: PlanComponentProps) {
    return (
        <CardComponent>
            <div className='divide-y-2 p-4 text-start'>
                <div className="py-4 text-start">
                    <div>
                        <h2 className={`font-semibold text-2xl mb-4`}>{type}</h2>
                        <p className={`text-sm font-light mb-4`}>{bestFor}</p>
                    </div>
                    <div className='flex items-center'>
                        <h1 className='text-4xl font-semibold'>{`$${price}`}</h1>
                        <p className='text-sm ml-2 font-light'> / Per month</p>
                    </div>
                </div>
                <div className='py-4'>
                    <h2 className='text-sm'>Features</h2>
                    <div className='py-2 '>
                        {benefits.map((benefit: string) => (
                            <h2 className='py-2' key={benefit}>{benefit}</h2>
                        ))}
                    </div>
                    <ButtonComponent bgColor='green-500' text='Upgrade' handleClick={handleClick} />
                </div>
            </div>


        </CardComponent>
    );
}
