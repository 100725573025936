import LottieAnimation from '../Components/LottieAnimation';
import animationData from '../animations/coming_soon.json';

function AssesmentTools() {
  return (
    <div className=' flex justify-center items-center flex-col  '>
    <LottieAnimation animationData={animationData} />
  </div>
  
  )
}

export default AssesmentTools;