
import { useState, useEffect } from "react"
import { Client } from "../models/client";
import { deleteClient, getClients } from "../firebase/firestoreAPI";
import { useNavigate } from "react-router-dom";
import TableComponent from "../Components/TableComponent";
import React from "react";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import ButtonComponent from "../Components/ButtonComponent";



export const ClientsPage = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const navigate = useNavigate();

  const fetchClients = async () => {
    try {
      const fetchedClients = await getClients();
      setClients(fetchedClients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      await deleteClient(id);
      toast.success('Client deleted succesfully!')
      await fetchClients();
    } catch (error) {

      console.log(error);
    }
  }

  const handleEdit = async (id: string) => {
    navigate(`/clients/edit/${id}`)

  }
  const columns: Column<Client>[] = React.useMemo(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Company', accessor: 'company' },
    ],
    []
  );
  const handleCreate = () => {
    navigate(`/clients/add`);
  }

  return (
    <div className="flex flex-col justify-center p-4">
      <Helmet>
        <title>Clients | ABA Summary</title>
        <meta name="description" content="Manage all your clients" />
      </Helmet>
      <div className="relative">
        <div className="pt-32 "> {/* Add padding-bottom to create space for the button */}
          <TableComponent data={clients} columns={columns} handleDelete={handleDelete} handleEdit={handleEdit} />
        </div>
        <div className="absolute top-0 right-0 ">
          <ButtonComponent text="Add client" handleClick={handleCreate} />
        </div>
      </div>
    </div>
  );
  
  



}
