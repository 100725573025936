import { signOut, getAuth } from 'firebase/auth';
import {  useNavigate } from 'react-router-dom';
import { HomeIcon, DocumentDuplicateIcon, UserIcon, MinusCircleIcon, InformationCircleIcon, Cog6ToothIcon, WrenchScrewdriverIcon,ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import SideBarItem from './SideBarItem';
import { UpgradeCardComponent } from './UpgradeCardComponent';
import { useEffect, useState } from 'react';

const auth = getAuth(); // Initialize Firebase auth

const items = [
  { label: 'Home', icon: HomeIcon },
  { label: 'Clients', icon: UserIcon, addRoute: '/clients/add' },
  { label: 'Summaries', icon: DocumentDuplicateIcon, addRoute: '/summaries/add' },
  { label: 'Assesments', icon: WrenchScrewdriverIcon },
  { label: 'Settings', icon: Cog6ToothIcon }

];
const SideBarComponent = ({ children }: { children: React.ReactNode }) => {

  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  };

  const checkWindowSize = () => {
    const isPhoneScreen = window.innerWidth < 640;
    setCollapsed(isPhoneScreen);
  };

  const handleLogout = () => {
    try {
      signOut(auth);
      navigate('/login');

      console.log('Logout success!');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);
    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);
  return (
    <div className="flex h-screen ">
      <div className={`flex flex-col h-screen py-4 overflow-y-hidden bg-white fixed px-6 ${collapsed ? 'w-16' : 'w-64 justify-between'} border-r-2 border-gray-200  transition-all  ease-out duration-300  `}>
        <div>
          <div className='flex justify-between py-2 border-b-2'>
            <h1 className={`font-light  text-gray-400 text-xl ${collapsed ? 'hidden' : ''}`}>LOGO</h1>
            <button onClick={toggleSidebar} >
              {collapsed ? (
                <ChevronRightIcon className="h-6 w-6 text-gray-400" />
              ) : (
                <ChevronLeftIcon className="h-6 w-6 text-gray-400" />
              )}
            </button>
          </div>
          <div className="mt-8">
            <ul className={`flex flex-col justify-center ${collapsed ? 'items-center' : 'items-start'} `}>
              {items.map((item) => (
                <SideBarItem collapsed={collapsed} label={item.label} icon={item.icon} to={`/${item.label.toLocaleLowerCase().replace(/\s/g, "")}`} addRoute={item.addRoute} />
              ))}
            </ul>
          </div>
        </div>

        <div className='flex flex-col justify-between '>
          <div className={` block ${collapsed ? 'hidden' : ''}`}>
            <UpgradeCardComponent />
          </div>
          <ul className={`flex flex-col justify-center ${collapsed ? 'items-center' : 'items-start'} `}>
            <SideBarItem collapsed={collapsed} label='Help' icon={InformationCircleIcon} to='/help' />
            <div onClick={handleLogout}>
              <SideBarItem collapsed={collapsed} label='Log out' icon={MinusCircleIcon} to='/login' />
            </div>
          </ul>
        </div>

      </div>
      <div className="flex-grow">
        <div className={`pl-4 mt-4 p-4 ${collapsed ? 'ml-12' : 'ml-60'} transition-all  ease-out duration-300 `}>
          {children}
        </div>
      </div>
    </div>
  );

}

export default SideBarComponent;

