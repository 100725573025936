import { Routes, Route, BrowserRouter } from 'react-router-dom';
import SignUpComponent from '../Components/SignUpComponent';
import LoginComponent from '../Components/LoginComponent';
import PasswordComponent from '../Components/ResetPassword';
import ChangePassword from '../Components/ChangePassword';
import NotFoundPage from './NotFoundPage';
import { LottieAnimation } from '../Components/LottieAnimation';
import animationData from '../animations/animation.json';
import { CardComponent } from '../Components/CardComponent';

export default function LoginPage() {
    return (
        <BrowserRouter>
            <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-2 scroll min-h-screen-safe p-4 relative">
                <div className="col-span-1 flex flex-col items-center  justify-center ">
                    <div className='p-4 md:self-start'>
                        <h1 className='font-light text-gray-200 text-xl'>LOGO</h1>
                    </div>
                    <CardComponent>
                        <Routes>
                            <Route path="/" element={<LoginComponent />} />
                            <Route path="/signup" element={<SignUpComponent />} />
                            <Route path="/login" element={<LoginComponent />} />
                            <Route path="/resetpassword" element={<PasswordComponent />} />
                            <Route path="/changepassword" element={<ChangePassword />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </CardComponent>
                </div>
                <div className=" flex justify-center items-center md:col-span-2 md:inline-flex">
                    <LottieAnimation animationData={animationData} />
                </div>

            </div>

        </BrowserRouter>
    );
}
