import React, { useRef, useEffect } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

interface LottieAnimationProps {
  animationData: any; // Replace 'any' with the appropriate type for your animation data
}

export const LottieAnimation: React.FC<LottieAnimationProps> = ({ animationData }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<AnimationItem | null>(null);
  
    useEffect(() => {
      if (containerRef.current) {
        animationRef.current = lottie.loadAnimation({
          container: containerRef.current,
          renderer: 'svg', // Change this to 'canvas' if needed
          loop: true,
          autoplay: true,
          animationData: animationData,
        });
  
        return () => {
          if (animationRef.current) {
            animationRef.current.destroy();
          }
        };
      }
    }, [animationData]);
  
    return <div className="w-80 h-80 " ref={containerRef} />;
  };
  
export default LottieAnimation;