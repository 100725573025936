
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
interface SideBarItemProps {
  icon: React.ElementType;
  label: string;
  to: string;
  addRoute?: string
  collapsed?:boolean
}

const SideBarItem: React.FC<SideBarItemProps> = ({ icon: Icon, label, to, addRoute,collapsed }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <li className="flex items-center justify-between text-gray-400  ">
      <Link to={to} className="py-4 xs:hidden">
        <div className={`flex items-center gap-4 hover:text-green-500 transition-colors duration-500 ${isActive ? `text-green-500` : ''}`}>
          <Icon className="h-6 w-6" />
          <div className={`${collapsed ? 'hidden' : ''}`}>
            {label}
          </div>
        </div >
      </Link>

      {addRoute && (
        <div className=' hover:text-green-500 transition-colors duration-500 hidden lg:block  right-0'>
          {/* <Link to={addRoute} >
            <PlusCircleIcon className="h-6 w-6" data-tooltip-id="new-tooltip" data-tooltip-content={`New ${label}`} />
          </Link> */}
          <Tooltip id="new-tooltip" className='' />
        </div>

      )}

    </li>
  );
};

export default SideBarItem;
