
import { useState, useEffect } from 'react';
import { auth } from '../firebase/firebaseConfig';

export default function HomePage() {

    const [userName, setUserName] = useState("");

    const getUserName = async () => {
        try {
            const user = auth.currentUser?.displayName;
            if (user)
                setUserName(user);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getUserName();
        return () => {

        }
    }, [])



    return (
        <div className="flex flex-col p-4">
            <div>
                <span className="font-bold text-3xl">Hello, </span>
                <span className="font-bold text-3xl text-green-500">{userName}</span>
            </div>

        </div>

    )
}
