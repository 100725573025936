import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
    organization: "org-wxgq9Ty0TMSLTMCz7j8JC4UJ",
    apiKey: process.env.REACT_APP_OPENAI_KEY
});

const openai = new OpenAIApi(configuration);

const openaiAPI = {
    generateSummary: async (data: string) => {
        try {
            const response = await openai.createCompletion({
                model: "text-davinci-003",
                prompt: `You are a professional RBT (Registred Behavior Technician), write a therapy session note for today session in one paragraph, use profesional vocabulary, make sure include all information provided detailed, and plans for the next sessions use this data :${data}`,
                temperature: 1.0,
                max_tokens: 3000,
                top_p: 1.0,
                frequency_penalty: 0.0,
                presence_penalty: 0.0,
            });
            const message = response.data.choices?.[0]?.text?.trim() || 'There is not enough information';
        
            return message;
            

        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
};

export default openaiAPI;
