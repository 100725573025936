import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import LoginPage from './Pages/LoginPage';
import HomePage from './Pages/HomePage';
import SideBarComponent from './Components/SideBarComponent';
import SummaryPage from './Pages/SummaryPage';
import { ClientsPage } from './Pages/ClientsPage';
import PlansPage from './Pages/PlansPage';
import HelpPage from './Pages/HelpPage';
import AddSummaryPage from './Pages/AddSummaryPage';
import AddClientPage from './Pages/AddClientPage';
import SettingsPage from './Pages/SettingsPage';
import EditClientPage from './Pages/EditClientPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssesmentTools from './Pages/AssesmentTools';
import NotFoundPage from './Pages/NotFoundPage';

const auth = getAuth(); // Initialize Firebase auth

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  return (
    isLoggedIn ? (
      <div className='bg-gray-50'>
        <BrowserRouter>
          <SideBarComponent>
            <ToastContainer position='bottom-right' />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/summaries" element={<SummaryPage />} />
              <Route path="/summaries/add" element={<AddSummaryPage />} />
              <Route path="/clients" element={<ClientsPage />} />
              <Route path="/clients/edit/:id" element={<EditClientPage />} />
              <Route path="/clients/add" element={<AddClientPage />} />
              <Route path="/assesments" element={<AssesmentTools />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/plans" element={<PlansPage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </SideBarComponent>
        </BrowserRouter >
      </div>

    ) : (
      <div>
        <LoginPage />
        <ToastContainer position='top-right' />
      </div>

    )
  );
};



export default App;


