import React from 'react';

export const CardComponent = ({ children,size }: { children: React.ReactNode, size?:string }) => {
    return (
        <div >
            <div className={`container p-4 border-2 border-gray-200 rounded-md py-6 bg-white  flex-grow` } >
                {children}
            </div>
        </div>
    );
};
