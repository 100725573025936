
import SubmitButton from '../Components/SubmitButton'
import { editClientData, getBehaviors, getClientData, getInterventions, getReplacements } from '../firebase/firestoreAPI';
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { SelectCompontent } from '../Components/SelectCompontent';
import { OptionType } from '../models/optionType';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { CardComponent } from '../Components/CardComponent';

function EditClientPage() {

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");

  const [behaviors, setBehaviors] = useState<OptionType[]>([]);
  const [interventions, setInterventions] = useState<OptionType[]>([]);
  const [replacements, setReplacements] = useState<OptionType[]>([]);

  const [behaviorsOptions, setBehaviorsOptions] = useState<OptionType[]>([]);
  const [interventionsOptions, setInterventionsOptions] = useState<OptionType[]>([]);
  const [replacementsOptions, setReplacementsOptions] = useState<OptionType[]>([]);

  const navigate = useNavigate();

  const fetchClientData = async () => {
    try {
      const clientData = await getClientData(id!);
      setName(clientData.name);
      setCompany(clientData.company);
      setBehaviors(clientData.behaviors);
      setInterventions(clientData.interventions);
      setReplacements(clientData.replacements);
   
    } catch (error) {
      console.error(error);

      // Handle error or display an error message
    }
  };

  const fetchData = async () => {
    fetchClientData()
    try {
      const fetchedBehaviors = await getBehaviors();
      const fetchedInterventions = await getInterventions();
      const fetchedReplacements = await getReplacements();

      setBehaviorsOptions(fetchedBehaviors.map((behavior: any) => ({ label: behavior.name, value: behavior.name })));
      setInterventionsOptions(fetchedInterventions.map((intervention: any) => ({ label: intervention.name, value: intervention.name })));
      setReplacementsOptions(fetchedReplacements.map((replacement: any) => ({ label: replacement.name, value: replacement.name })));

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    try {
   
      const clientData = {
        name,
        company,
        behaviors,
        interventions,
        replacements
      };
      setIsLoading(true);
      await editClientData(id!, clientData);

      setIsLoading(false);

      navigate('/clients');

    } catch (error) {
      console.error(error);
      setIsLoading(false);
      // Handle error or display an error message
    }
  };

  useEffect(() => {

    fetchData();

  },)

  const handleUpload = () => {
    toast.error('Upload function coming soon.');
  };

  return (
    <div className='p-4'>
      <CardComponent>
        <form className=''>
          <div className='mb-4'>
            <label htmlFor="name" className='block mb-2 text-sm font-bold'>Name:</label>
            <input value={name} onChange={(event) => setName(event.target.value)} className="w-full border-gray-300 border rounded p-2" />
          </div>

          <div className='mb-4'>
            <label htmlFor="company" className='block mb-2 text-sm font-bold'>Company:</label>
            <input value={company} onChange={(event) => setCompany(event.target.value)} className="w-full border-gray-300 border rounded p-2" />
          </div>

          <div className='mb-4'>
            <label htmlFor="behaviors" className='block mb-2 text-sm font-bold'>Behaviors:</label>
            <SelectCompontent isMulti create values={behaviors} options={behaviorsOptions} onChange={(selectedBehaviors: OptionType[]) => setBehaviors(selectedBehaviors)} />
          </div>

          <div className='mb-4'>
            <label htmlFor="interventions" className='block mb-2 text-sm font-bold'>Interventions:</label>
            <SelectCompontent isMulti create itemsColor='yellow-500' values={interventions} options={interventionsOptions} onChange={(selectedInterventions: OptionType[]) => setInterventions(selectedInterventions)} />
          </div>

          <div className='mb-4'>
            <label htmlFor="replacements" className='block mb-2 text-sm font-bold'>Replacements:</label>
            <SelectCompontent isMulti create itemsColor='indigo-500' values={replacements} options={replacementsOptions} onChange={(selectedReplacements: OptionType[]) => setReplacements(selectedReplacements)} />
          </div>

          <p className='text-center my-4 font-light text-blue-500'>Or upload the client assement</p>

          <div onClick={handleUpload} className='border-2 border-dashed  rounded p-4 flex flex-col border-blue-300 text-blue-500 hover:bg-blue-300 hover:bg-opacity-20 justify-center items-center gap-2 cursor-pointer hover:opacity-100'>
            <CloudArrowUpIcon className='w-12 h-12' />
            <div className='text-center'>
              <p className='text-xl font-semibold'>Upload client assessment</p>
              <p className='text-sm font-light'>Maximum file size 50 MB.</p>
            </div>

          </div>
          <SubmitButton text='Save' isLoading={isLoading} handleClick={handleSubmit} />
        </form>
      </CardComponent>
    </div>

  )


}

export default EditClientPage;