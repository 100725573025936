
import { CardComponent } from './CardComponent'
import { Link } from 'react-router-dom'

export const UpgradeCardComponent = () => {
    return (
        <CardComponent >
            <div className='space-y-4 flex flex-col justify-center '>
                <span className='text-center font-light'>Get 1 month free and unlock</span>
                <button
                    className={`flex items-center justify-center mt-8 w-full bg-green-500 text-white rounded px-4 py-2 transition ease-in-out delay-150 hover:-translate-y--1 hover:scale-110 duration-300`}>
                    <Link to={'/plans'}>
                        Upgrade
                    </Link>
                </button>

            </div>

        </CardComponent>


    )
}
