import React from 'react';

interface ButtonProps {
    text: string;
    bgColor?: string;
    handleClick: () => void;
    disabled?: boolean;
}

const ButtonComponent: React.FC<ButtonProps> = ({ text, handleClick, disabled, bgColor }) => {
    return (
        <button
            onClick={handleClick}
            disabled={disabled}
            className={`${bgColor ? `bg-${bgColor} text-white` : 'bg-white text-green-500'} border-green-500 border-solid border-2 flex items-center justify-center mt-8 w-full  rounded px-4 py-2 transition ease-in-out delay-150 hover:-translate-y-1  duration-300`}
        >
            {text}
        </button>
    );
};
export default ButtonComponent;