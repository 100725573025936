
import { collection, getDocs, QuerySnapshot, DocumentData, addDoc, getDoc, doc, setDoc, deleteDoc, query, where } from 'firebase/firestore'; // Import the necessary Firestore functions
import { auth, db } from './firebaseConfig';
import { Client } from '../models/client';
import { OptionType } from '../models/optionType';

export const getClients = async () => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error('User is not authenticated.'); // Handle the case when the user is not authenticated
  }

  const clientCollectionsRef = collection(db, 'clients');

  try {
    // Query Firestore to get client documents where therapistId matches the authenticated user's ID
    const q = query(clientCollectionsRef, where('therapistId', '==', userId));

    const data: QuerySnapshot<DocumentData> = await getDocs(q);
    return (
      data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as Client[]
    );
  } catch (error) {
    throw error;
  }
};
export const getClientData = async (id: string) => {

  const clientDocRef = doc(db, 'clients', id);
  try {
    const client = await getDoc(clientDocRef);
    if (client.exists()) {
      return client.data() as DocumentData;
    } else {
      throw new Error(`Client with ID ${id} not found`);
    }
  } catch (error) {
    console.error(error);
    throw new Error('Error retrieving client data');
  }
};
export const editClientData = async (id: string, newData: any) => {
  const clientDocRef = doc(db, 'clients', id);
  try {
    await setDoc(clientDocRef, newData, { merge: true });
    console.log('Client data updated successfully');
  } catch (error) {
    console.error(error);
    throw new Error('Error updating client data');
  }
};

export const addClient = async ({
  therapistId,
  name,
  company,
  behaviors,
  interventions,
  replacements,
}: {
  therapistId: string;
  name: string;
  company: string;
  behaviors: OptionType[];
  interventions: OptionType[];
  replacements: OptionType[]; 
}) => {
  try {
    await addDoc(collection(db, 'clients'), {
      therapistId,
      name,
      company,
      behaviors,
      interventions,
      replacements,
    });
    console.log('Added successfully');
  } catch (error) {
    throw error;
  }
};
export const deleteClient = async (id: string) => {
  const clientDocRef = doc(db, 'clients', id);
  try {
    await deleteDoc(clientDocRef);
    console.log('Client deleted successfully');
  } catch (error) {
    console.error(error);
    throw new Error('Error deleting client');
  }
};

export const getBehaviors = async () => {

  const behaviorsCollectionsRef = collection(db, 'behaviors');
  try {
    const data: QuerySnapshot<DocumentData> = await getDocs(behaviorsCollectionsRef);
    return (
      data.docs.map((doc) => ({
        ...doc.data()

      })) as OptionType[]
    );
  } catch (error) {
    throw error;
  }

};


export const getInterventions = async () => {
  
  const interventionsCollectionsRef = collection(db, 'interventions');
  try {
    const data: QuerySnapshot<DocumentData> = await getDocs(interventionsCollectionsRef);
    return (
      data.docs.map((doc) => ({
        ...doc.data(),

      })) as OptionType[]
    );
  } catch (error) {
    throw error;
  }
};

export const getReplacements = async () => {
  
  const replacementsCollectionsRef = collection(db, 'replacements');
  try {
    const data: QuerySnapshot<DocumentData> = await getDocs(replacementsCollectionsRef);
    return (
      data.docs.map((doc) => ({
        ...doc.data(),

      })) as OptionType[]
    );
  } catch (error) {
    throw error;
  }

};

export const getPlaces = async () => {

  const placesCollectionsRef = collection(db, 'places');
  try {
    const data: QuerySnapshot<DocumentData> = await getDocs(placesCollectionsRef);
    return (
      data.docs.map((doc) => ({
        ...doc.data(),

      })) as OptionType[]
    );
  } catch (error) {
    throw error;
  }
};
export const getPresents = async () => {

  const presentsCollectionsRef = collection(db, 'presents');
  try {
    const data: QuerySnapshot<DocumentData> = await getDocs(presentsCollectionsRef);
    return (
      data.docs.map((doc) => ({
        ...doc.data(),

      })) as OptionType[]
    );
  } catch (error) {
    throw error;
  }
};
export const getSettings = async () => {

  const settingsCollectionsRef = collection(db, 'settings');
  try {
    const data: QuerySnapshot<DocumentData> = await getDocs(settingsCollectionsRef);
    return (
      data.docs.map((doc) => ({
        ...doc.data(),

      })) as OptionType[]
    );
  } catch (error) {
    throw error;
  }
};
export const getParticipation = async () => {

  const participationCollectionsRef = collection(db, 'participation');
  try {
    const data: QuerySnapshot<DocumentData> = await getDocs(participationCollectionsRef);
    return (
      data.docs.map((doc) => ({
        ...doc.data(),

      })) as OptionType[]
    );
  } catch (error) {
    throw error;
  }
};
export const getAntecedents = async () => {

  const antecedentsCollectionsRef = collection(db, 'antecedents');
  try {
    const data: QuerySnapshot<DocumentData> = await getDocs(antecedentsCollectionsRef);
    return (
      data.docs.map((doc) => ({
        ...doc.data(),

      })) as OptionType[]
    );
  } catch (error) {
    throw error;
  }
};
export const getReinforcers = async () => {

  const reinforcersCollectionsRef = collection(db, 'reinforcers');
  try {
    const data: QuerySnapshot<DocumentData> = await getDocs(reinforcersCollectionsRef);
    return (
      data.docs.map((doc) => ({
        ...doc.data(),

      })) as OptionType[]
    );
  } catch (error) {
    throw error;
  }
};











