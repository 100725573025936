import React from 'react';

interface SubmitButtonProps {
  text: string;
  bgColor?:string;
  handleClick: (event: React.FormEvent) => Promise<void>;
  isLoading: boolean;
  disabled?:boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ text, handleClick, isLoading,disabled,bgColor}) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={` ${bgColor ? bgColor : 'bg-green-500'} ${disabled? `bg-opacity-40 `: ``} flex items-center justify-center mt-8 w-full bg-green-500 text-white rounded px-4 py-2 transition ease-in-out delay-150 hover:-translate-y-1  duration-300`}
    >
      {isLoading ? (
        <svg className="h-5 w-5 mr-3 animate-spin" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : (
        text
      )}
    </button>
  );
};
export default SubmitButton;