import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import { Link } from 'react-router-dom';
import SubmitButton from './SubmitButton';
import { toast } from 'react-toastify';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';


const PasswordComponent = () => {

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(event.target.value);
    };

    const handleReset = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            await sendPasswordResetEmail(auth, email)
            toast.success(`A reset email was sent to ${email}.`)

        } catch (error) {
            console.log(error);
            toast.error('Something was wrong, try again.')

        }
        setIsLoading(false);
    };

    return (
        <div className="w-full flex flex-col items-center gap-4">
            <div className='pb-4'>
                <h2 className="text-2xl font-bold text-center ">Forgot password?</h2>
                <p className="pt-4 text-sm">No worries, we'll send you reset instructions.</p>
            </div>

            <form className="w-full max-w-xs flex flex-col ">
                <div className="mb-4">
                    <label htmlFor="email" className="block mb-2 text-sm font-bold">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        className="w-full border-gray-300 border rounded p-2 bg-white"
                    />
                </div>

                <SubmitButton text='Reset password' handleClick={handleReset} isLoading={isLoading} disabled={!email} />
            </form>
            <div className="pt-6 text-sm flex justify-center items-center gap-4 text-gray-400 hover:text-green-500 transition-colors duration-500">
                <ArrowLeftIcon className='h-6 w-6' />
                <Link to="/login" className="">Back to Log in </Link>
            </div >
        </div>
    );
};

export default PasswordComponent;