import { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase/firebaseConfig';
import { toast } from 'react-toastify';
import SubmitButton from './SubmitButton';

const SignUpComponent = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setIsChecked(event.target.checked);
  };

  const handleSignUp = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(userCredentials.user, {
        displayName: name
      })
      console.log(userCredentials.user);
      navigate('/home');
    } catch (error: any) {
      toast.error('This user already exists, please Log in.')

    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2 px-10  ">
      <div className='mb-6 text-center'>
        <h2 className="text-3xl font-bold pb-4">Create your account</h2>
        <p className='text-sm text-gray-500'>
          Take the first step towards optimizing your ABA practice.
        </p>
      </div>
      <form className="w-full max-w-xs mt-4">
        <div className="mb-4">
          <label htmlFor="name" className="block mb-2 text-sm font-bold">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            className="w-full border-gray-300 border rounded p-2 bg-white "
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 text-sm font-bold">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className="w-full border-gray-300 border rounded p-2 bg-white"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-4 text-sm font-bold">
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className="w-full border-gray-300 border rounded p-2 bg-white"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="checkbox" className="flex items-center">
            <input
              type="checkbox"
              id="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={`mr-2 ${isChecked ? 'bg-green-500' : ''} cursor-pointer`}
            />
            <span className="text-sm">
              I agree to the <Link to="/terms&conditions" className="text-green-500">terms and conditions</Link>
            </span>
          </label>
        </div>
        <SubmitButton text='Sign up' handleClick={handleSignUp} isLoading={isLoading} disabled={!email || !password || !isChecked} />
      </form>
      <div>
        <p className="pt-4 text-sm">
          Already have an account? <Link to="/login" className="text-green-500">Log in</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpComponent;